import React from "react"
import './BookList.scss'

const BookListItem = ({children, title, id=false}) => {

    return <div className={"list-block"} id={id}>
        <div className="title c-main-dark">{title}</div>
        <div className="list-block-content book_list">
            {children}
        </div>
    </div>
}

export default BookListItem