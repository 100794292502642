import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {analyticsBACK} from "../../ApiEndpoints";
import {setUserData, setAuthData} from "../../reducers/userSlice";
import FormErrors from "../ui/FormErrors";
import {HandySvg} from "handy-svg";
import {Link} from 'react-router-dom';

import svg_arrow from '../../ico/arrow.svg';
import FeedbackForm from "../ui/FeedbackForm";

const AuthForm = () => {
	const [errors, setErrors] = useState([])
	const [value, setValue] = useState({login: '', password: ''})
	const [formBusy, setFormBusy] = useState(false)
	const dispatch = useDispatch()

	const handleAuth = (e) => {
		e.preventDefault()
		setErrors([])
		if(!value.password){
			setErrors(['Введите пароль'])
			return false
		}
		if(!value.login){
			setErrors(['Введите логин'])
			return false
		}

		setFormBusy(true)

		var formData = new FormData();
		formData.append('username', value.login);
		formData.append('password', value.password);
		analyticsBACK.post('/oauth/login', formData)
			.then((resp) => {
				if (resp.status === 200) {
					analyticsBACK.get('/getCurrentUser')
						.then((resp) => {
							if(resp && resp.data && resp.data.data && resp.data.data.currentUser){
								dispatch(setAuthData(true))
								dispatch(setUserData(resp.data.data.currentUser))
							}
						})
						.catch((resp) => {
							dispatch(setAuthData(false))
							setFormBusy(false)
							if(resp.response.data.message)
								setErrors([resp.response.data.message])
							else
								setErrors(['Не удалось авторизовать пользователя, проверьте правильность вводимых данных'])
							console.error("Auth Fail")
						})
				} else{
					setFormBusy(false)
					setErrors(['Произошла непредвиденная ошибка, повторите попытку позже'])
					console.error("Auth Fail")
				}
			})
			.catch((resp) => {
				setFormBusy(false)
				if(resp && resp.response && resp.response.status === 401)
					setErrors(['Такой пользователь не найден', 'Проверьте правильность введенных данных'])
				else if(resp.response && resp.response.data){
					if(resp.response.data.message)
						setErrors([resp.response.data.message])
					else
						setErrors(['Произошла непредвиденная ошибка, повторите попытку позже'])
				}
				else
					setErrors(['Произошла непредвиденная ошибка, повторите попытку позже'])
				console.error("Auth Fail")
			})
	}

	const customValidity = (message, event) => {
		event.target.setCustomValidity("")
		if(!event.target.validity.valid)
			event.target.setCustomValidity(message)
	}
	const resetValidityMessage = (event) => {
		event.target.setCustomValidity("")
	}

	return <>
		<form action="" aria-disabled={formBusy} className="nice-form js-auth-form" onSubmit={(e) => handleAuth(e)}>
			<div className="cols cols-2 p10" >

				<div className="col">
					<label className="input-wrap">
						<input
							name='login'
							type='text'
							placeholder=" "
							value={value.login}
							onChange={(e) => setValue({...value, login: e.target.value})}
							onInput={(e) => resetValidityMessage(e)}
							onInvalid={(e) => customValidity('Введите логин', e)}
							required={true}
						/>
						<span className="input-wrap__label">Логин / email</span>
					</label>
				</div>

				<div className="col">
					<label className="input-wrap">
						<input
							name='password'
							type='password'
							placeholder=" "
							value={value.password}
							onChange={(e) => setValue({...value, password: e.target.value})}
							onInput={(e) => resetValidityMessage(e)}
							onInvalid={(e) => customValidity('Заполните пароль', e)}
							required={true}
						/>
						<span className="input-wrap__label">Пароль</span>
					</label>
				</div>
			</div>

			<div className="form-message"><FormErrors errors={errors} /></div>

			<p className={'formFooter'}>
				<button type='submit' className="btn big">
					Авторизация
					<HandySvg src={svg_arrow} width={20} height={18}/>
				</button>
			</p>
			<div className="flex_row main-margin" style={{margin: "2rem 0"}}>
				<Link to="/invite" className="text-btn">
					Регистрация
				</Link>
				&nbsp; &nbsp; | &nbsp;&nbsp;
				<Link to='/restore-password'  className="text-btn">
					Восстановление пароля
				</Link>
				&nbsp; &nbsp; | &nbsp;&nbsp;
				<FeedbackForm className="text-btn"/>
			</div>
		</form>
	</>
}


export default AuthForm