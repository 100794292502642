import Modal from "./Modal";
import React, {useState} from "react";
import {analyticsBACK} from "../../ApiEndpoints";

export default function FeedbackForm({className}){
    const [modalOpen, setModalOpen] = useState(false)
    const [error, setError] = useState([])
    const [success, setSuccess] = useState(false)

    const sendFeedback = (e) => {
        e.preventDefault()

        const formData = new FormData(e.target)
        setError([])
        analyticsBACK.post('/send-feedback', formData)
            .then((resp) => {
                // console.log("resp", resp)
                setSuccess(true)
                setTimeout(() => {
                    setSuccess(false)
                }, 3500)
                setTimeout(() => {
                    setModalOpen(false)
                },3000)
            })
            .catch((resp) => {
                setError(['Ошибка при отправки сообщения.'])
            })
    }

    return <>
        <button className={'mt__normal mb__normal text-btn ' + className} onClick={(e) => {
            e.preventDefault()
            setModalOpen(true)
        }}>Обратная связь
        </button>
        <Modal className="user_edit" opened={modalOpen} setOpened={setModalOpen}>
            <div className='title c-main-dark'>Форма обратной связи</div>
            {success
                ? <div>Ваше сообщение успешно отправлено.</div>
                : <form onSubmit={sendFeedback} className="nice-form js-auth-form">
                    <div className="cols cols-2 p10">
                        <div className="col">
                            <label className="input-wrap">
                                <input
                                    name='name'
                                    type={'text'}
                                    placeholder=" "
                                    required={true}
                                />
                                <span className="input-wrap__label">Ваше имя</span>
                            </label>
                        </div>

                        <div className="col">
                            <label className="input-wrap">
                                <input
                                    name='email'
                                    type={'text'}
                                    placeholder=" "
                                    required={true}
                                />
                                <span className="input-wrap__label">Ваш email</span>
                            </label>
                        </div>

                        <div className="col">
                            <label className="input-wrap">
                                <input
                                    name='phone'
                                    type={'text'}
                                    placeholder=" "
                                    required={true}
                                />
                                <span className="input-wrap__label">Ваш телефон</span>
                            </label>
                        </div>

                        <div className="col"></div>
                    </div>
                    <textarea name="text" className='mt__normal' style={{width: '100%'}}
                              placeholder={'Сообщение'}></textarea>
                    {error.length > 0 &&
                        <div className='mb__normal mt__normal'>{error[0]}</div>
                    }
                    <button type="submit" className='btn small mt__normal'>Отправить</button>
                </form>
            }
        </Modal>
    </>
}